import React from 'react'
import styled from 'styled-components'
import HeaderFront from '../components/Headers/HeaderFront'
import { useLocation } from 'react-router-dom'
type IProps = {
  children: React.ReactNode // 👈️ type children
}
const DashboardLayout = (props: IProps) => {
  const {pathname} = useLocation();
  const pathArray = pathname.split("/")
  return (
    <>
    {pathArray[2] && pathArray[2] =="watch" ?
    <></>
    :<HeaderFront />}
      
      {props.children}
    </>
  )
}


export default DashboardLayout
