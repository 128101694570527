import React from "react";
import styled from "styled-components";
import loader from "../../assets/2.json";
import Lottie from "react-lottie";
import theme from "../../theme";
import { device } from "../../config/devices";
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
interface IProps {
    label?: string;
    onClick?: () => void;
    isSubmit?: boolean;
    loading?: boolean;
    color?: string;
    icon?: JSX.Element;
    width?: string;
    backgroundOnHover?: string;
}

const Loadding = () => {
    return <>
         <Lottie options={defaultOptions} width={200} />
    </>
};
export default Loadding;