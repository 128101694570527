import React, { ReactElement, useState } from "react";
import TabItem, { TabListProps, TabProps } from "./TabItem";
import styled from "styled-components";
import { sanitizeForId } from "../../util/stringUtils";

const TabList: React.FC<TabListProps> = ({ children, activeTabIndex = 0 }) => {
    const [activeTab, setActiveTab] = useState(activeTabIndex);
    const handleTabClick = (index: number) => {
      setActiveTab(index);
    };
    const tabs = React.Children.toArray(children).filter(
        (child): child is ReactElement<TabProps> =>
          React.isValidElement(child) && child.type === TabItem
    );
      return (<Tabs>
        <TabListUl role="tablist" aria-orientation="horizontal">
          {tabs.map((tab, index) => (
            <li key={`tab-${index}`}>
              <button
                key={`tab-btn-${index}`}
                role="tab"
                id={`tab-${sanitizeForId(tab.props.label)}`}
                aria-controls={`panel-${sanitizeForId(tab.props.label)}`}
                aria-selected={activeTab === index}
                onClick={() => handleTabClick(index)}
                className={`tab-btn ${
                  activeTab === index && "tab-btn--active"
                }`}
              >{tab.props.label}</button>
            </li>
          ))}
          </TabListUl>
          <TabContent>
          {tabs[activeTab]}
          </TabContent>
      </Tabs>)
};
export default TabList;
const Tabs = styled.nav`
  width:100%;min-height:100px; 
  background-color:#fff;
  border:1px solid #e0e0e0;
  margin-bottom:10px;
  
`;
const TabListWrapper = styled.nav`
  width:100%;
`;
const TabContent = styled.nav`
    width:94%;margin:80px auto 30px ;
`;
const TabListUl = styled.ul`
  margin:15px auto;padding:0;
  width:70%;
  li{
  float:left;list-style:none;
  margin-right:1%;
  width:32%;
  button{
  width:100%;
  font-family:Montserrat;
  font-weight:bold;padding:10px 0;
  outline:none
  }
  }
`;