import { observer } from 'mobx-react-lite'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {  Avatar, Col, Dropdown, FlexboxGrid, Row, Tabs } from 'rsuite'
import styled from 'styled-components'
import React, { useState } from 'react'
import { useCoursePresenter } from '../instrutor/cursos/presenter'
import { Paragraph, ParagraphBold } from '../../../components/Typography'
import Theme from '../../../theme'
import { FaCirclePlay, FaFacebook, FaInstagram, FaLinkedin, FaList, FaPlay, FaSquareCheck, FaTiktok, FaYoutube } from 'react-icons/fa6'
import { Modals } from '../../../components'
import { Modals as Modal, useUiStore } from '../../../stores/uiStore'
import { useUserPresenter } from '../user/presenter'
import { FaAward, FaComment, FaComments, FaTasks } from 'react-icons/fa'
import Loadding from '../../../components/loadding/page'
import { useQuill } from "react-quilljs";
import { logo } from '../../../images'
import { useUserStore } from '../../../stores/userStore'
import TabWatch from '../../../components/TabSimple/TabWatch'
import { Layout, theme } from 'antd'

const { Sider, Content } = Layout;
const Watch: React.FC = observer(() => {
    const { id_course } = useParams();
    const { purchased, setVideoId,updatePurchased} = useUserPresenter()
    const navigate = useNavigate()
    const { setModal } = useUiStore()
    const { loadding,getItemModuleId,resetStoreValuesItemModule1,item_module_list,item_module, course,getItemModuleByCourseId, getModulesByCourseId, moduleList, getQtdAula } = useCoursePresenter()
    const placeholder = 'Por exemplo: aos 56:13, não entendi essa parte ...';
    const theme1 = 'snow';
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { user } = useUserStore()
    const renderToggle = (props: any) => (
        <Avatar style={{ margin:"auto", }} circle {...props} src={user ? user.img_url : ""} />
    );
    const [text,setText] = useState('');
    
      const handleChange= (html:any)=> {
          setText(html);
      }
      React.useEffect(() => {
       if(id_course){
            getItemModuleId(id_course as string);
        }else{
            getItemModuleByCourseId(purchased?.id_course as string)
            if(item_module_list){
                setVideoId(item_module_list[0].video_external_id as string)
                updatePurchased()
                navigate(`/dashboard/watch/${item_module_list[0].video_external_id}`)
            
            }
            
        }
    }, [item_module_list])
    const siderStyle: React.CSSProperties = {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        borderRight:"1px solid #e9e9e9",
        background: "#fff", minHeight: '100vh'
      };
    return (
        <> 
        <Layout style={{margin:0,padding:0}}>
        <Sider 
        trigger={null} 
        collapsible collapsed={collapsed}
        width={400} 
        style={siderStyle}
        >
            {collapsed ? <Paragraph>Conteúdo curricular do curso</Paragraph> :
            <>
            {moduleList &&  
            <FlexboxGrid justify="start">
                <FlexboxGrid.Item colspan={2}>
                    <FaList  size={22}  style={{margin:13}} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={22}>
                    <ParagraphBold size="14px" style={{ margin: 12 }}>
                     Conteúdo curricular do curso
                    </ParagraphBold>
                </FlexboxGrid.Item>
            </FlexboxGrid>}
            {moduleList && 
              <>
             <hr style={{margin:0,marginBottom:10}}/>
             {moduleList.map((item, i) => (
             <><FlexboxGrid   justify="space-between" style={{ margin: 8 }}>
                <FlexboxGrid.Item colspan={19}>
                <ParagraphBold >{i + 1} - {item.title}</ParagraphBold>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={5}>
                <Paragraph style={{textAlign:"right"}}>
                <FaAward size={13}  color='#999' /> 0/{item.items_module?.length} {"   "} 00:00
                </Paragraph>
                </FlexboxGrid.Item>
                </FlexboxGrid>
                {item.items_module && <>
                {item.items_module.map((item1, a) => (
                <Link 
                to={`/dashboard/watch/${item1.video_external_id}`} 
                onClick={()=>{
                resetStoreValuesItemModule1()
                getItemModuleId(item1.video_external_id as string);
                }} 
                key={a}
                style={{ textDecoration: "none" }}>
                <GridItem>
                 <FlexboxGrid justify="space-between" style={{ margin: 8 }}>
                 <FlexboxGrid.Item colspan={2}>
                 {item_module?.id == item1.id ?
                  <FaCirclePlay  size={19} color={Theme.BACKGROUND.GREEN}/>
                  : <> 
                    <FaSquareCheck color='#999' size={19}/>
                    </>}
                  </FlexboxGrid.Item>
                   <FlexboxGrid.Item colspan={18}>
                     <Paragraph size="13px" >{a + 1} - {item1.titulo}</Paragraph>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                    <Paragraph>
                      <FaPlay color='#999' />  {item1.time}
                    </Paragraph>
                    </FlexboxGrid.Item>
                    </FlexboxGrid>
                    </GridItem>
                    </Link>
                    ))}</>}
             </>) )} </>}</>
            }
       </Sider>
        <Layout style={{ marginInlineStart: 400 }}>
        <Content
          style={{
            margin: '10px',
            padding: 0,
            minHeight: 280,
            background: "transparent",
            borderRadius: borderRadiusLG,
          }}
        >
        <Header>
            <FlexboxGrid justify="space-between" style={{  }}>
                <FlexboxGrid.Item colspan={12} xl={5} as={Col} md={8}  xs={9} >
                <Link to={`/`}>
                  <Image src={logo} />
                </Link>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} xxl={1} xl={2} colspan={6} md={3} xs={4}>
                    <Dropdown renderToggle={renderToggle} placement="bottomEnd" >
                        <Dropdown.Item panel style={{ padding: 10, width: 220 }}>
                        <Paragraph style={{ padding: 0, margin: 0 }}>Logado como :</Paragraph>
                        <ParagraphBold style={{ padding: 0, margin: 0 }}>{user?.name}</ParagraphBold>
                        </Dropdown.Item >
                        <Dropdown.Separator />
                        <Dropdown.Item onClick={() => {
                            navigate('/dashboard/user/meu-percurso')
                        }}>
                            <ParagraphBold>Meu percurso</ParagraphBold>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            navigate('/dashboard/instrutor')
                        }}>
                        <ParagraphBold>Painel de instrutor</ParagraphBold>
                    </Dropdown.Item>
                    <Dropdown.Item>
                    <ParagraphBold>Painel de instituição</ParagraphBold>
                  </Dropdown.Item>
                  <Dropdown.Separator />
                  <Dropdown.Item onClick={() => {
                    navigate('/dashboard/user/config')
                  }}><ParagraphBold>Configurações de conta</ParagraphBold> </Dropdown.Item>
                  <Dropdown.Item onClick={() => {
                    navigate('/dashboard/user/editar-profile')
                  }}
                  ><ParagraphBold>Editar perfil</ParagraphBold> </Dropdown.Item>
                  <Dropdown.Item><ParagraphBold>Ajuda</ParagraphBold> </Dropdown.Item>
                  <Dropdown.Item><ParagraphBold>Sair</ParagraphBold></Dropdown.Item>
                </Dropdown>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Header>    
        {id_course && item_module ? 
            <ContentPlayer>
                <Row className="show-grid">
                  <Col sm={24} md={24} lg={24} xs={24}>
                      {item_module &&
                      <div style={{position:"relative", paddingTop:"53.25%", marginBottom:10}}>
                      <iframe 
                      id={`panda-${item_module.video_external_id}`} 
                      src={item_module.video_player}
                      style={{border:"none", position:"absolute", top:0, left:0}} 
                      allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" 
                      allowFullScreen={true} 
                      width="100%" 
                      height="100%" />
                      </div>
                      }
                      
                      <TabWatch/>
                  </Col>
                </Row>
            </ContentPlayer> :
            !id_course ?<>
            <Loadding/>
            </> :
            loadding && id_course && <Loadding/>
    }
    <Footer>
    <Row className="show-grid" style={{ margin: 6 }}>
      <Col xxl={14} xl={14} md={14} xs={24} >
        <Paragraph color='#000' size="14px" style={{ marginTop: 10 }}>
          © 2024 <Link to={""}><strong>Rosa Academy</strong>.</Link> Todos direitos reservados.
        </Paragraph>
      </Col>
      <Col xxl={10} xl={10} md={10} xs={24} >
        <FlexboxGrid justify="end" style={{ marginTop: 10 }}>
          <FlexboxGrid.Item colspan={4}>
            <Link to={""}>
            <FaTiktok color='#999' size={30} />
            </Link>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4}>
              <Link to={""}>
                <FaInstagram color='#999' size={30} />
              </Link>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4}>
              <Link to={""}>
                <FaFacebook color='#999' size={30} />
              </Link>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4}>
              <Link to={""}>
                <FaLinkedin color='#999' size={30} />
              </Link>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3}>
              <Link to={""}>
                <FaYoutube color='#999' size={30} />
              </Link>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Col>
      </Row>        
    </Footer>
        </Content>
      </Layout>
    </Layout>
    </>
    )
})

export default Watch
interface IProps {
    backgroundImage?: string
}
export const ContentPlayer = styled.div`
  width:100%;margin:0 auto;
 
`
export const Header = styled.header`
  width:100%;margin:0;padding:10px 0;
  background-color:#fff;
  margin-bottom:10px;
  min-height:10px;
  margin-top:-20px;
  border:1px solid #e0e0e0;

`
export const Footer = styled.footer`
  width:100%;margin:0;padding:10px 0;
  background-color:#fff;
  margin-bottom:10px;
  min-height:10px;
  border:1px solid #e0e0e0;

`
export const Player = styled.div`
  width:94%;margin:0 auto;
`

const Image = styled.img`
  width:100%;margin-top:3.5%;margin-left:10px;
`
const GridItem = styled.div`
  width:96%;margin:0 auto;
  background-color:#fff;
  margin-bottom:7px;
  border:1px solid #e0e0e0;
  min-height:14px;
`
const FormWrapper = styled.div`
 min-height:150px;
  padding:0px;
`