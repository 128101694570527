import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import { ChangeEvent } from 'react'
import container from '../../../stores/ioc'
import { useClassStore } from '../../../util/useClassStore'
import NavigationStore from '../../../stores/navigationStore'
import SupabaseGateway from '../../../gateways/SupabaseGateway'
import UIStore from '../../../stores/uiStore'
import UserStore from '../../../stores/userStore'
import PurchasedCourseStore from '../../../stores/purchasedCourseStore'
import CourseStore from '../../../stores/courseStore'
@injectable()
class UserPresenter {
    [key: string]: string | undefined | any
    @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway
    @inject(UIStore) ui!: UIStore
    @inject(UserStore) private userStore!: UserStore
    @inject(CourseStore) private courseStore!: CourseStore
    @inject(PurchasedCourseStore) private purchasedStore!: PurchasedCourseStore

    loadding = false
    imgSrc = ""
    purchased!: IPurchasedCourse
    constructor() {
        makeAutoObservable(this)
    }
    getLinkWatchCourse = (purchased:IPurchasedCourse) => {
        this.purchased = purchased;
        if(this.purchased.lasted_view_id){
           return "/dashboard/watch/"
        }else{
            return "/dashboard/watch/"
        }
        
    }
    getLinkWatchPlayer = (purchased:IPurchasedCourse) => {
        this.purchased = purchased;
        if(this.purchased.lasted_view_id){
           
        }else{

        }
        return "/dashboard/watch/"
    }
    setVideoId = (value: string) => {
        this.purchased = { ...this.purchased, lasted_view_id: value }
    };
    updatePurchased = async () => {
        try {
            console.log(this.purchased)
            delete this.purchased.cursos;
            await this.purchasedStore.updatePurchased(this.purchased)
        } catch (error) { }
    }
    setImgSrc = (value: string) => {
        this.imgSrc = value
    }
    get user() {
        return this.userStore.user
    }
    uploadImageProfile = async (img: File) => {
        try {
            this.loadding = true;
            await this.userStore.uploadImage(img);
            this.imgSrc = ""
        } catch (error) {

        } finally {
            this.loadding = false
        }
    }
    get purchasedList(): IPurchasedCourse[] {
        return this.purchasedStore.purchasedList
    }
}
export const useUserPresenter = () =>
    useClassStore<UserPresenter>(container.get(UserPresenter))

export default UserPresenter