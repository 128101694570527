import { observer } from 'mobx-react-lite'
import { FaHouseUser, FaPlus, FaReact, FaRegMoneyBillAlt, FaRocket, FaTrash, FaUserGraduate } from 'react-icons/fa'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { Paragraph, ParagraphBold, Title } from '../../../../../components/Typography'
import React from 'react'
import { Col, InlineEdit, Input, Row } from 'rsuite';
import { useCoursePresenter } from '../presenter'
import { MdEdit, MdOutlineDesktopWindows } from "react-icons/md";
import Button from '../../../../../components/Buttons/Button'
import theme from '../../../../../theme'
import styled from 'styled-components'
import { Drawers, Modals } from '../../../../../components'
import { Modals as Modal, useUiStore } from '../../../../../stores/uiStore'
import TextInputModule from '../../../../../components/Inputs/TextInputModule'
import { device } from '../../../../../config/devices'
const ManageGrade: React.FC = observer(() => {
    const { getCourseId, course, setModule, setItemModule, getModulesByCourseId,
        moduleForm, moduleList, addFormModule, loadding, saveModules,
        removeFormModule, setValueModuleForm } = useCoursePresenter()
    let { id_course } = useParams();
    const { setModal } = useUiStore()
    React.useEffect(() => {
        getCourseId(id_course as string);
        getModulesByCourseId(id_course as string)
    }, [])

    return (
        <>
            <Title color='#000' style={{ margin: 0, marginLeft: 0 }}>{course?.titulo}</Title>
            <Paragraph color="#888" style={{  marginTop: 10 }}>Grade curricular do curso</Paragraph>
            <hr />
            <Paragraph size="13">
                Comece a montar seu curso criando seções, aulas e atividades práticas (testes, exercícios de codificação e tarefas). Use seu resumo do curso para estruturar o conteúdo e identificar as seções e aulas com clareza. Se você pretende oferecer seu curso gratuitamente, a duração total do conteúdo em vídeo deve ter menos de 2 horas.
            </Paragraph>
            {moduleList && <>
                {moduleList.map((item, i) => (
                    <ModuloGrade>
                        <Row className="show-grid">
                            <Col xxl={14} xl={14} xs={24}>
                                <Paragraph size="13" style={{ marginTop: 6 }}><strong>Módulo {i + 1} : </strong>{item?.title}</Paragraph>
                            </Col>
                            <Col xxl={10} xl={10} xs={24}>
                                <ButtonGroupModule>
                                    <MyButtonModule
                                        onClick={() => {
                                            setModule(item)
                                            setModal(Modal.ITEM_MODULE_MODAL, true)
                                        }}
                                    >
                                        <ParagraphBold>
                                            <FaPlus /> Item da grade curricular
                                        </ParagraphBold>
                                    </MyButtonModule>
                                    <MyButtonModule>
                                        <FaTrash color={theme.BACKGROUND.RED_THEME} />
                                    </MyButtonModule>
                                    <MyButtonModule>
                                        <MdEdit color={theme.BACKGROUND.GREEN} />
                                    </MyButtonModule>
                                </ButtonGroupModule>
                            </Col>
                        </Row>
                        {item?.items_module && <>
                            {item?.items_module.map((item1, index) => (
                                <ItemModuloGrade>
                                    <Row className="show-grid">
                                        <Col xxl={14} xl={14} xs={24}>
                                            <Paragraph size="13" style={{ marginTop: 4 }}><strong>Aula {index + 1} : </strong>{item1?.titulo}</Paragraph>
                                        </Col>
                                        <Col xxl={10} xl={10} xs={24}>
                                            <ButtonGroupModule>
                                                <MyButtonModule
                                                    onClick={() => {
                                                        setModule(item)
                                                        setItemModule(item1)
                                                        setModal(Modal.EDIT_ITEM_MODULE_MODAL, true)
                                                    }}>
                                                    <MdEdit />
                                                </MyButtonModule>
                                                <MyButtonModule>
                                                    <MdOutlineDesktopWindows />
                                                </MyButtonModule>
                                                <MyButtonModule>
                                                    <FaTrash />
                                                </MyButtonModule>
                                            </ButtonGroupModule>
                                        </Col>
                                    </Row>
                                </ItemModuloGrade>
                            ))}
                        </>}
                    </ModuloGrade>
                ))}
            </>
            }
            {moduleForm && <>
                {moduleForm.map((item, i) => (
                    <ModuloGrade>
                        <ParagraphBold color='#000'>Título do módulo</ParagraphBold>
                        <TextInputModule
                            placeholder='Digite um título :'
                            name='title'
                            onChange={e => setValueModuleForm(i, e)}
                            icon={<MdEdit size={24} style={{ padding: 12 }} />}
                        />
                        <ParagraphBold color='#000'>O que os alunos poderão fazer ao final desta seção?                        </ParagraphBold>
                        <TextInputModule
                            name='objective'
                            onChange={e => setValueModuleForm(i, e)}
                            placeholder='Escrever um objectivo de aprendizado :'
                            icon={<MdEdit size={24} style={{ padding: 12 }} />}
                        />
                        <ButtonGroupModule>
                            <Button
                                label='Cancelar'
                                width='20'
                                color={theme.BACKGROUND.RED_THEME}
                                onClick={() => removeFormModule(i)}
                            />
                            <Button
                                label='Adicionar módulo'
                                width='20'
                                loading={loadding}
                                onClick={saveModules}
                            />
                        </ButtonGroupModule>
                    </ModuloGrade>
                ))}
            </>}
            {
                moduleForm.length == 0 && <ButtonGroupModule>
                    <Button
                        label='Adicionar um novo módulo'
                        icon={<FaPlus />}
                        width='50'
                        color={theme.BACKGROUND.BLUE}
                        onClick={addFormModule}
                    />
                </ButtonGroupModule>
            }
            <Modals.ItemModuleModal />
            <Modals.EditItemModuleModal />
        </>
    )
})

export default ManageGrade
const ModuloGrade = styled.div`
width:100%;min-height:10px; 
background-color:#f8f8f8;  
border:1px solid #e0e0e0;
padding:18px;
margin-top:10px;
@media ${device.mobileL} {
   padding:8px;
}
`
const ItemModuloGrade = styled.div`
width:100%;
background-color:#ffff;  
border:1px solid #e0e0e0;
padding:10px;
margin-top:10px;
`
const ButtonGroupModule = styled.div`
width:100%;
display: flex;
flex-direction: row;
justify-content: flex-end;
margin-top:10px;
`
const MyButtonModule = styled.button`
margin-right:5px;
`