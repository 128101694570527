import React from "react";
import styled from "styled-components";
import loader from "../../assets/loadding.json";
import Lottie from "react-lottie";
import theme from "../../theme";
import { device } from "../../config/devices";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

interface IProps {
    label?: string;
    onClick?: () => void;
    isSubmit?: boolean;
    loading?: boolean;
    color?: string;
    icon?: JSX.Element;
    width?: string;
    backgroundOnHover?: string;
}

const Button = ({
    label,
    onClick,
    isSubmit,
    loading,
    color,
    icon,
    width,
    backgroundOnHover,
}: IProps) => {
    return (
        <>
            {icon ? (
                <>
                    <MyButton
                        color={color}
                        type={isSubmit ? "submit" : "button"}
                        onClick={onClick}
                        width={width}
                        backgroundOnHover={backgroundOnHover}
                        disabled={loading }
                    >
                        {!loading ? (
                            <div>
                                {icon}
                                {label && "    "+label}
                            </div>
                        ) : (
                            <Lottie options={defaultOptions} height={25} width={25} />
                        )}
                    </MyButton>
                </>
            ) : (
                <MyButton
                    color={color}
                    type={isSubmit ? "submit" : "button"}
                    onClick={onClick}
                    width={width}
                    backgroundOnHover={backgroundOnHover}
                    disabled={loading }
                >
                    {!loading ? (
                        label
                    ) : (
                        <Lottie options={defaultOptions} height={25} width={25} />
                    )}
                </MyButton>
            )}
        </>
    );
};

type StyledButton = {
    color?: string;
    width?: string;
    backgroundOnHover?: string;
};

export const MyButton = styled.button<StyledButton>`
  background: ${({ color }) => color || theme.BACKGROUND.THEME};
  height:50px;
  width:${({ width }) => width || 100}%;
  padding: 8px;
  font-weight: bold;
  font-size: 14px;
  color: #fff;outline:none;
  margin-top: 10px;
  cursor: pointer;margin-right:1%;
  font-family:Montserrat;
  transition: 0.3s;
  :hover {
    
  }
  @media ${device.mobileL} {
    height:40px;
    padding: 5px;
    font-weight: bold;
    font-size: 12px;
  }
  @media ${device.mobileL} {
    width:100%;border:0
  }
`;
export default Button;
