import React from 'react'
import { observer } from 'mobx-react-lite'
import Modal from './ModalWrapper'
import { useUiStore, Modals } from '../../stores/uiStore'
import { styled } from 'styled-components'
import { Tabs } from 'rsuite'
import { useCoursePresenter } from '../../pages/dashboard/instrutor/cursos/presenter'
import { Paragraph } from '../Typography'
import TextInputLarge from '../Inputs/TextInputLarge'
import { MdEdit, MdLink } from 'react-icons/md'

const EditItemModuleModal: React.FC = observer(() => {
    const ui = useUiStore()
    const { setModal } = ui
    const { module, loadding, updateItemModule, setFormValueItem, item_module } = useCoursePresenter()
    return (
        <Modal
            isOpened={ui[Modals.EDIT_ITEM_MODULE_MODAL]}
            buttonLabel="SALVAR"
            title="EDITAR ITEM "
            loadding={loadding}
            size='lg'
            onClose={() => {
                setModal(Modals.EDIT_ITEM_MODULE_MODAL, false)
            }}
            onSubmit={updateItemModule}>
            <ModuloGrade>
                <Paragraph style={{ padding: 16 }}><strong>Módulo :</strong> {module?.title}</Paragraph>
            </ModuloGrade>
            <Tabs defaultActiveKey="1">
                <Tabs.Tab eventKey="1" title="Título">
                    <FormWrapper>
                        <TextInputLarge
                            placeholder='Escrever um título :'
                            name='titulo'
                            defaultValue={item_module?.titulo}
                            onChange={setFormValueItem}
                            icon={<MdEdit size={24} style={{ padding: 12 }} />}
                        />
                    </FormWrapper>
                </Tabs.Tab>
                <Tabs.Tab eventKey="2" title="Id da vídeo aula">
                    <FormWrapper>
                        <TextInputLarge
                            placeholder='Id da vídeo aula :'
                            name='uuid'
                            defaultValue={item_module?.uuid}
                            onChange={setFormValueItem}
                            icon={<MdLink size={24} style={{ padding: 12 }} />}
                        />
                    </FormWrapper>
                </Tabs.Tab>
            </Tabs>
        </Modal >
    )
})
export default EditItemModuleModal
const FormWrapper = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
  padding:10px;
`
const ModuloGrade = styled.div`
width:100%; 
background-color:#f8f8f8;
margin-top:10px;margin-bottom:10px;
`
const InputGroup = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`
