import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import TabList from "./TabList";
import TabItem from "./TabItem";
import { Col, FlexboxGrid } from "rsuite";
import { ParagraphBold } from "../Typography";
import ButtonBorder from "../Buttons/ButtonBorder";
import { FaComment } from "react-icons/fa6";
import { Modals } from '../../components'
import { Modals as Modal, useUiStore } from '../../stores/uiStore'
import theme from "../../theme";
const TabWatch = () => {
      const { setModal } = useUiStore()
      
      return (
      <><TabList activeTabIndex={0}>
        <TabItem label="Dúvidas">
          <Quetions>
            <FlexboxGrid justify="space-between" style={{  }}>
                <FlexboxGrid.Item colspan={12} xl={14} as={Col} md={8}  xs={9} >
                    <ParagraphBold size="18px" style={{marginTop:10}}>
                        Perguntas & Respostas
                    </ParagraphBold>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} xxl={3} xl={7} colspan={6} md={3} xs={4}>
                    <ButtonBorder
                    label="Enviar Pergunta"
                    width="100"
                    icon={<FaComment />}
                    color={theme.BACKGROUND.BLUE}
                    onClick={() => {
                      setModal(Modal.ADD_QUETION_MODAL, true)
                  }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
          </Quetions>
        </TabItem>
        <TabItem label="Avaliação">
          <p>Selected by default, this is tab #2.</p>
        </TabItem>
        <TabItem label="Informações ">
          <p>Tab #3, the last tab.</p>
        </TabItem>
      </TabList>
      <Modals.AddQuetionModal  />
      </>)
};
export default TabWatch
const Tabs = styled.nav`
  
`;
const Quetions = styled.nav`
  width:100%;
`;
const TabListWrapper = styled.nav`
  
`;
const TabListUl = styled.ul`
  
`;