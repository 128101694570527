import { observer } from 'mobx-react-lite'
import { Link} from 'react-router-dom'
import React from 'react'
import {Col, FlexboxGrid,  Rate} from 'rsuite';
import { Container, Content } from '../../style';
import { Paragraph, ParagraphBold, Title } from '../../../../components/Typography';
import styled from 'styled-components';
import { useUserPresenter } from '../presenter'
import { device } from '../../../../config/devices'
import { Filter } from '../../../../components/Inputs/Filter'
import { useCoursePresenter } from '../../instrutor/cursos/presenter';
const options = [
    { value: "bySurname", label: "Mais novo" },
    { value: "byIdNumber", label: "Mais Antigo" },
    { value: "byAZ", label: "A-Z" },
    { value: "byZA", label: "Z-A" },
];
const Percurso: React.FC = observer(() => {
    const { purchasedList,getLinkWatchCourse } = useUserPresenter()
    const { resetStoreItemCourse } = useCoursePresenter()
    return (
        <Container>
            <Content>

                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={12} as={Col} xs={24} >
                        <Title color="#000" style={{ marginTop: 10}}>Meu percurso</Title>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4} as={Col} xs={24} style={{ display: "flex" }} >
                        <Filter options={options} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <CourseContent>
                    {purchasedList && <>
                        {purchasedList.map((item, i) => (
                            <CardCourse>
                                <Link 
                                    to={getLinkWatchCourse(item)} 
                                    style={{ textDecoration: "none" }}
                                    onChange={() =>{
                                        resetStoreItemCourse()
                                    }}
                                >
                                    <img src={item?.cursos?.img_url} />
                                    <ParagraphBold style={{ margin: 10 }}>
                                        {item?.cursos?.titulo}
                                    </ParagraphBold>
                                    <Paragraph size="11px" color='#777' style={{ marginLeft: 10, marginRight: 10, marginTop: 0 }}>Em Andamento (<strong>20%</strong>)</Paragraph>
                                    <Paragraph size="11px" style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                                        <Rate readOnly defaultValue={4} color='yellow' size='xs' allowHalf />
                                    </Paragraph>
                                </Link>
                            </CardCourse>
                        ))}
                    </>}
                </CourseContent>

            </Content>
        </Container>
    )
})

export default Percurso
export const CardCourse = styled.div`
    width:18%;min-height:280px;
    border:1px solid #e0e0e0;
    background-color:#fff;
    border-radius:5px;
    margin-bottom:10px;
    margin-right:10px;
    img{width:100%}
    @media ${device.laptopL} {
        width:14%
    }
    @media ${device.mobileL} {
    width:46%
    }
`

const CourseContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start ;
  flex-wrap: wrap;
  margin-top:20px
`