import React from "react";
import styled from "styled-components";

interface ITextArea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  rows?: number;
  required?: boolean;
  placeholder?: string;
  hasLabel?: boolean;
  label?: string;
}
interface IProps {
  width?: number;
}

export default function TextArea({
  rows = 5,
  placeholder,
  hasLabel,
  label,
  ...rest
}: ITextArea) {
  return (
    <Group>
      {hasLabel && <label>{label || placeholder}</label>}
      <Area rows={rows} placeholder={placeholder} {...rest} />
    </Group>
  );
}

export const Area = styled.textarea`
  padding: 8px;
  background: transparent;
  background-color: hsl(0, 0%, 100%);
  border-color: #999;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: #333;
  font-family:Montserrat;
  font-size: 14px;
  margin-bottom: 0px;
  outline:none;resize:none
`;

const Group = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 100}%;
`;
