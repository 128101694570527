import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { logo } from '../../images'
import THEME from "../../theme/"
import { Link, useNavigate } from 'react-router-dom'
import { FaSearch } from "react-icons/fa";
import { Paragraph, ParagraphBold } from '../Typography'
import TextInputSearchMain from '../Inputs/TextInputSearchMain'
import { Drawers as Drawer, useUiStore } from '../../stores/uiStore'
import { Drawers } from '../../components'
import { useUserStore } from '../../stores/userStore'
import { Avatar, Col, Dropdown, Row } from 'rsuite'
import { device } from '../../config/devices'
import Button from '../Buttons/Button'
import { FaBars, FaUserPlus } from 'react-icons/fa6'
import theme from '../../theme/'
const HeaderFront: React.FC = observer(() => {
  const { setDrawer } = useUiStore()
  const { user } = useUserStore()
  const navigate = useNavigate()
  const renderToggle = (props: any) => (
    <Avatar style={{ marginTop: 10 }} circle {...props} src={user.img_url ? user.img_url : ""} />
  );
  return (
    <>
      <TopHeader>
        <Menu>
        <Row className="show-grid" style={{padding:0, margin:0}}>
          <Col lg={6} xl={5} xxl={6} md={12} sm={24} xs={24} style={{padding:0, margin:0}}>
              <Link to="/training-center">
                <Paragraph color='#fff'>PARA CENTROS DE FORMAÇÃO</Paragraph>
              </Link>
          </Col>
          <Col lg={6} xl={4} xxl={6} md={12} sm={24} xs={24} style={{padding:0, margin:0}}>
            <Link to="/university">
              <Paragraph color='#fff'>PARA UNIVERSIDADES</Paragraph>
            </Link>
          </Col>
          <Col lg={6} xl={3} xxl={6} md={12} sm={24} xs={24}  style={{padding:0, margin:0}}>
            <Link to="/company">
              <Paragraph color='#fff'>PARA EMPRESAS</Paragraph>
            </Link>
          </Col>
          <Col lg={6} xl={6} xxl={6} md={12} sm={24} xs={24}  style={{padding:0, margin:0}}>
            <Link to="">
              <Paragraph color='#fff'>PARA GOVERNOS</Paragraph>
            </Link>
          </Col>
        </Row>
        </Menu>
      </TopHeader>
      <Container>
        <Header>
        <Row className="show-grid">
        <Col lg={4} xl={4} xxl={4} md={5} xs={4} lgHidden xlHidden xxlHidden>
          <Link 
            to={"?"}
            onClick={() => {
              setDrawer(Drawer.MENU_MOBILE_DRAWER, true)
            }}  
            >
            <FaBars color='#000' style={{ marginTop: 8 }} size={30} />   
          </Link>
        </Col>
          <Col lg={3} xl={3} xxl={4} md={5} xs={10} >
            <AppLogo>
              <Link to={"/"}>
                <Logo src={logo} />
              </Link>
            </AppLogo>
          </Col>
          <Col lg={10} xl={9} xxl={10}  xlOffset={1} xxlOffset={1} mdHidden xsHidden>
            <TextInputSearchMain
              placeholder='O que você deseja aprender ?'
              width={100}
              icon={<FaSearch size={18} style={{ padding: 8 }} color='#999' />}
            />
          </Col>
          {user ?<>
           <Col lg={4} xl={8} xxl={5}  xs={4}  mdHidden  xsHidden style={{ marginTop: 13}}>
           <ParagraphBold>
            <Link to="?*" style={{marginRight:13, color:"#000"}} >
             Ensine na Rosa Academy
           </Link>
           <Link to="/dashboard/user/meu-percurso" style={{color:"#000"}} >
             Meu Percurso
           </Link>
           </ParagraphBold>
          </Col>
          <Col lg={2} xl={1} xxl={2} md={9} xs={4} mdHidden  xsHidden >
              <Dropdown renderToggle={renderToggle} placement="bottomEnd" >
                  <Dropdown.Item panel style={{ padding: 10, width: 220 }}>
                    <Paragraph style={{ padding: 0, margin: 0 }}>Logado como :</Paragraph>
                    <ParagraphBold style={{ padding: 0, margin: 0 }}>{user?.name}</ParagraphBold>
                  </Dropdown.Item >
                  <Dropdown.Separator />
                  <Dropdown.Item onClick={() => {
                    navigate('/dashboard/user/meu-percurso')
                  }}>
                    <ParagraphBold>Meu percurso</ParagraphBold>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => {
                    navigate('/dashboard/instrutor')
                  }}>
                    <ParagraphBold>Painel de instrutor</ParagraphBold>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <ParagraphBold>Painel de instituição</ParagraphBold>
                  </Dropdown.Item>
                  <Dropdown.Separator />
                  <Dropdown.Item onClick={() => {
                    navigate('/dashboard/user/config')
                  }}><ParagraphBold>Configurações de conta</ParagraphBold> </Dropdown.Item>
                  <Dropdown.Item onClick={() => {
                    navigate('/dashboard/user/editar-profile')
                  }}
                  ><ParagraphBold>Editar perfil</ParagraphBold> </Dropdown.Item>
                  <Dropdown.Item><ParagraphBold>Ajuda</ParagraphBold> </Dropdown.Item>
                  <Dropdown.Item><ParagraphBold>Sair</ParagraphBold></Dropdown.Item>
                </Dropdown>
              </Col></>: 
              <>
              <Col lg={6} xl={8} xxl={6} md={9} xs={24} mdHidden  xsHidden>
                <ParagraphBold style={{marginTop:12}}>
                <Link to="?*" style={{marginRight:13, color:"#000"}} >
                  Ensine na Rosa Academy
                </Link>
                <Link to="?*" style={{color:"#000"}}
                onClick={() => {
                  setDrawer(Drawer.LOGIN_DRAWER, true)
                }}   
                >
                  Entrar
                </Link>
                
                </ParagraphBold> 
              </Col>
              <Col lg={1} xl={1} xxl={1} md={2} xs={24} mdHidden  xsHidden>
              <ButtonLogin 
                 onClick={() => {
                  setDrawer(Drawer.SIGNUP_DRAWER, true)
                  }} >
                    <FaUserPlus size={20} />
                </ButtonLogin>
              </Col>
              </>
              }
        </Row>
        </Header>
      </Container >
      <Drawers.LoginDrawer />
      <Drawers.SignUpDrawer />
      <Drawers.MenuMobile />
    </>
  )
})

const Container = styled.div`
  width: 100%;min-height:50px;
  background-color:#fff;
  border-bottom:1px solid #e0e0e0;
  @media ${device.mobileL} {
    
  }
`
const TopHeader = styled.div`
  width: 100%;min-height:10px;
  padding:10px 0;
  background-color:${THEME.BACKGROUND.BLACK};
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${device.mobileL} {
    display:none
  }
`
const Menu = styled.div`
 width:90%;padding:10x 0;
 a{
  color:#fff
 }
`
const ButtonLogin = styled.button`
width:100%; height:50px;
background-color:#e0e0e0

`
const Header = styled.div`
  width: 90%;
  margin:0 auto;
`
const AppLogo = styled.div`
  width:100%;margin-top:10px;
  @media ${device.mobileL} {
    margin-top:8px;
  }
 
`
const Logo = styled.img`
  width:100%;margin-top:6px;
`
const ButtonGroup = styled.div`
 width:40%;
 list-style:none;  
 display: flex;
 flex-direction: row;
 margin-top:7px;
 justify-content: flex-end ;
 a {
  padding:0 0;
  color:#000;
  text-decoration:none
 }
`
const LinkLogin = styled.div`
  margin-top:10px;
  padding:8px 15px; 
  border:1px solid ${THEME.BACKGROUND.BLACK};
  
`

export default HeaderFront
