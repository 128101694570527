import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { logo, logo1 } from '../../images'
import THEME from "../../theme/"
import { Link, useNavigate } from 'react-router-dom'
import { FaInstagram, FaSearch } from "react-icons/fa";
import { Paragraph, ParagraphBold } from '../Typography'
import TextInputSearchMain from '../Inputs/TextInputSearchMain'
import { Drawers as Drawer, useUiStore } from '../../stores/uiStore'
import { Drawers } from '../../components'
import { useUserStore } from '../../stores/userStore'
import { Avatar, Col, Dropdown, FlexboxGrid, Grid, Row } from 'rsuite'
import { FaFacebook, FaLinkedin, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa6'


const FrontFooter: React.FC = observer(() => {
    const { setDrawer } = useUiStore()
    const { user } = useUserStore()
    const navigate = useNavigate()
    const renderToggle = (props: any) => (
        <Avatar style={{ marginTop: 10 }} circle {...props} src={user.img_url ? user.img_url : ""} />
    );
    return (
        <Footer>
            <FooterContent>
                <FooterMenu>
                <Grid fluid >
                    <Row className="show-grid">
                        <Col lg={8} xl={8} xxl={8} xs={24}>
                            <ul>
                                <li><Link to={""}>Blog</Link></li>
                                <li><Link to={""}>Quem somos</Link></li>
                                <li><Link to={""}>Fale conosco</Link></li>
                                <li><Link to={""}>Obtenha o aplicativo</Link></li>
                                <li><Link to={""}>Ensine na Rosa Academy</Link></li>
                            </ul>
                        </Col>
                        <Col lg={8} xl={8} xxl={8} xs={24}>
                            <ul>
                                <li><Link to={""}>Governos</Link></li>
                                <li><Link to={""}>Universidades</Link></li>
                                <li><Link to={""}>Empresas </Link></li>
                                <li><Link to={""}>Centros de formação</Link></li>
                            </ul>
                        </Col>
                        <Col lg={8} xl={8} xxl={8} xs={24}>
                            <div >
                                <Link to={""}>
                                    <LogoFooter src={logo1}/>
                                </Link>
                            </div>
                            <Paragraph size="13px" style={{ textAlign: "center" }} color='#fff'>Tel : <strong>+244 923 542 638</strong></Paragraph>
                            <Paragraph size="13px" style={{ textAlign: "center" }} color='#fff'>Email: geral@rosaacademy.com</Paragraph>
                        </Col>
                    </Row>
                    </Grid>
                </FooterMenu>
                <Row className="show-grid" style={{ marginTop: 30 }}>
                    <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24} >
                        <Paragraph color='#fff' size="15px">© 2024 <strong>Rosa Academy</strong>. Todos direitos reservados.</Paragraph>
                    </Col>
                    <Col lg={12} xl={12} xxl={12} md={24} sm={24} xs={24}  style={{ marginTop: 10 }}>
                        <FlexboxGrid justify="end">
                            <FlexboxGrid.Item colspan={5} as={Col} md={4} >
                                <Link to={"https://www.tiktok.com/@rosa.academy.ao"}>
                                    <FaTiktok color='#fff' size={38} />
                                </Link>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={5} as={Col} md={4}>
                                <Link to={"https://www.instagram.com/rozacademy.ao/"}>
                                    <FaInstagram color='#fff' size={38} />
                                </Link>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={5} as={Col} md={4}>
                                <Link to={"https://www.facebook.com/rozacademy.ao/"}>
                                    <FaFacebook color='#fff' size={38} />
                                </Link>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={5} as={Col} md={4}>
                                <Link target='' to={"https://www.linkedin.com/company/rozacademy/?viewAsMember=true"}>
                                    <FaLinkedin color='#fff'  size={38} />
                                </Link>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4} as={Col} md={4}>
                                <Link to={"https://www.youtube.com/@rozacademy"}>
                                    <FaYoutube color='#fff' size={38} />
                                </Link>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                    </Col>
                </Row>
            </FooterContent>
            <Drawers.LoginDrawer />
            <Drawers.SignUpDrawer />
            <Drawers.MenuMobile />
        </Footer>
    )
})

export default FrontFooter
const Footer = styled.footer`
  width:100%;min-height:300px; 
  background-color: ${THEME.BACKGROUND.BLACK};
  display: flex;
  padding-bottom:30px;
  justify-content: center;
`
const LogoFooter= styled.img`
  width:170px;
  margin:20px 8%;
`
const FooterContent = styled.div`
  width: 82%;
  justify-content: center;
`

const FooterMenu = styled.div`
  width: 70%;
  margin: auto;
  margin-top:30px;
  ul{
  width:100%;margin:0;padding:0;
  margin-top:16px;
  list-style:none;
  }
  li{
    width:100%;
    text-align:center;margin-bottom:7px;
  }
  a{
  text-decoration:none;
  color:#fff;
  font-family:Montserrat;
  :hover {
    text-decoration: underline;
  }
  }
  
`