/* eslint-disable no-template-curly-in-string */
import { Underline } from 'react-feather'
import * as yup from 'yup'

// @TODO: internationalize fields.
yup.setLocale({
  string: {
    min: ' deve ter no mínimo ${min} caracteres',
    max: ' deve ter no máximo ${max} caracteres',
    uuid: 'Usuario não autenticado',
    trim: ': elimine os espaços a direita/esquerda',
    email: ' deve ser um email válido',
    matches: ' deve conter apenas números',
  },
  mixed: {
    required: ' é um campo obrigatório'
  }
})

export const ONBOARDING_FORM_VALIDATOR = yup.object().shape({
  name: yup.string().strict(true).min(2).required().trim(),
  email: yup.string().email().required()
})
export const SIGNUP_FORM_VALIDATOR = yup.object().shape({
  name: yup.string().strict(true).required().trim(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .strict(true)
    .min(6).required(),
  confirm_password: yup
    .string()
    .strict(true)
    .min(6)
    .oneOf([yup.ref("password"),undefined], " deve coincidir ")
    .required(),
})
export const COURSE_FORM_VALIDATOR = yup.object().shape({
  titulo: yup.string().strict(true).min(2).required().trim(),
  categoria: yup.string().strict(true).min(1).required().trim(),
  para_quem: yup.string().strict(true).min(2).required().trim(),
})
export const ITEMS_MODULE_FORM_VALIDATOR = yup.object().shape({
  titulo: yup.string().strict(true).min(2).required().trim(),
  uuid: yup.string().strict(true).min(2).required().trim(),
 })

export const getValidationErrorMessage = (
  error:
    | 'titulo'
    | 'para_quem'
    | 'membership_number'
    | 'name'
    | 'password'
    | 'new_password'
    | 'confirm_password'
    | 'first_name'
    | 'last_name'
    | 'home_address'
    | 'city'
    | 'uuid'
    | 'email_address'
    | 'phone_number'
    | 'dob'
    | 'id_number'
    | 'notes'
    | 'categoria'
    | 'next_of_kin.name'
    | 'next_of_kin.relationship'
    | 'next_of_kin.email'
    | 'next_of_kin.phone'
    | 'next_of_kin.home'
    | 'next_of_kin.city'
    | 'next_of_kin.country'
) => {
  switch (error) {
    case 'membership_number':
      return 'Número de registro'
    case 'titulo':
      return 'Título'
    case 'para_quem':
      return 'Público Alvo'
    case 'name':
      return 'Nome'
    case 'first_name':
      return 'Nome'
    case 'password':
      return 'Palavra-passe'
    case 'new_password':
      return 'Nova Senha'
    case 'confirm_password':
      return 'Palavra-passe'
    case 'last_name':
      return 'Sobrenome'
    case 'home_address':
    case 'next_of_kin.home':
      return 'Endereço'
    case 'city':
    case 'next_of_kin.city':
      return 'Cidade'
    case 'uuid':
      return 'Id'
    case 'next_of_kin.country':
      return 'País'
    case 'email_address':
    case 'next_of_kin.email':
      return 'Email'
    case 'phone_number':
    case 'next_of_kin.phone':
      return 'Número de telefone'
    case 'dob':
      return 'Data de nascimento'
    case 'id_number':
      return 'Número de identificação'
    case 'notes':
      return 'notas'
    case 'categoria':
      return 'Categoria'
    case 'next_of_kin.relationship':
      return 'Parentesco'
    default:
      return ''
  }
}
