import React from 'react'
import { observer } from 'mobx-react-lite'
import Modal from './ModalWrapper'
import { useUiStore, Modals } from '../../stores/uiStore'
import { styled } from 'styled-components'
import { Tabs } from 'rsuite'
import { useCoursePresenter } from '../../pages/dashboard/instrutor/cursos/presenter'
import { Paragraph, ParagraphBold } from '../Typography'
import TextInputLarge from '../Inputs/TextInputLarge'
import { MdEdit, MdLink } from 'react-icons/md'
import { useQuill } from "react-quilljs";
import Quill from 'quill'
import TextArea from '../Inputs/TextArea'

const AddQuetionModal= observer(() => {
    const ui = useUiStore()
    const { setModal } = ui
    const { loadding, setFormValueItem, addItemModule } = useCoursePresenter()
  
    return (
        <Modal
            isOpened={ui[Modals.ADD_QUETION_MODAL]}
            buttonLabel="ADICIONAR "
            title="ADICIONAR UMA PERGUNTA"
            loadding={loadding}
            onClose={() => {
                setModal(Modals.ADD_QUETION_MODAL, false)
            }}
            onSubmit={addItemModule}>
           <FormWrapper>
            <ParagraphBold>
                Título ou resumo
            </ParagraphBold>
            <TextInputLarge
                placeholder='Por exemplo : aonde usamos o elemento X para Y ?'
                name='titulo'
                onChange={setFormValueItem}
                icon={<MdEdit size={24} style={{ padding: 12 }} />}
            />
            <ParagraphBold style={{marginBottom:10}}>Detalhes (opcional)</ParagraphBold>
            <TextArea
                label='opcional'
                placeholder='Exemplo: No minuto 04:34 tive dúvidas relacionada com ...'
            />
            </FormWrapper>

        </Modal >
    )
})
export default AddQuetionModal
const FormWrapper = styled.div`
 min-height:250px;
  padding:10px;
`
