import { observer } from 'mobx-react-lite'
import { FaHouseUser, FaReact, FaRegMoneyBillAlt, FaRocket, FaUserGraduate } from 'react-icons/fa'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { Content } from '../../../style'
import { Paragraph, ParagraphBold, Title } from '../../../../../components/Typography'
import TextInputLarge from '../../../../../components/Inputs/TextInputLarge'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa6'
import Select from "react-select";
import React from 'react'
import { InlineEdit, Input } from 'rsuite';
import { useCoursePresenter } from '../presenter'
import { categoriasCurso, levelCurso } from '../../../../../config/static'
import Button from '../../../../../components/Buttons/Button'
import theme from '../../../../../theme'
import { useQuill } from "react-quilljs";
const ManageMessage: React.FC = observer(() => {
    const placeholder = 'Escrever mensagem de boas vindas...';
    const theme1 = 'snow';
    const modules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        
        ],
      };
      const formats = ['bold', 'italic', 'underline', 'strike'];
    const { quill, quillRef } = useQuill({modules,theme:theme1,placeholder});
    const { getCourseId, course } = useCoursePresenter()
    let { id_course } = useParams();
    React.useEffect(() => {
        getCourseId(id_course as string);
    }, [])
    
  React.useEffect(() => {
    if (quill) {
      }
  }, [quill]);

    return (
        <>
            <Title color='#000' style={{ margin: 0, marginLeft: 0 }}>{course?.titulo}</Title>
            <Paragraph size="18px" color="#888" style={{  marginTop: 10 }}>Mensagem</Paragraph>
            <hr />
            <Paragraph size="13">
                Escreva mensagens para seus alunos (opcional) que serão enviadas automaticamente quando eles se inscreverem ou concluírem seu curso, incentivando-os a interagir com o conteúdo. Se você não quiser enviar mensagens de boas-vindas ou parabenização, deixe a caixa de texto em branco.
            </Paragraph>
            <ParagraphBold style={{marginBottom:10}}>Mensagem de Boas-vindas</ParagraphBold>
            <div style={{ width: "100%", height: 100, position:"relative" }}>
            <div ref={quillRef} />
            </div>
            <br/><br/>
            <Button label='Salvar informações do curso' width='50' color={theme.BACKGROUND.BLUE} />
        </>
    )
})

export default ManageMessage
